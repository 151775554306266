<template>
    <div class="pageContainer targetPage flexCloumn">
        <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
            <div class="flexStart flex_1" style="flex-wrap:wrap;">

                <el-input placeholder="班级名称" v-model="searchForm.class_name" size="small" class="input-with-select"
                    style="width:200px" clearable></el-input>

                <el-input placeholder="工号或姓名" v-model="searchForm.teacher_name" size="small" class="input-with-select"
                    style="width:200px;margin-left: 10px" clearable></el-input>

                <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
                    @click="() => { page.current_page = 1; getList() }">搜索</el-button>

                <el-button icon="el-icon-plus" style="margin-left:10px" size="small" type="primary"
                    @click="saveJh">添加院系实习总结</el-button>
            </div>

            <div class="flexStart"
                style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">

                <!-- <el-button v-if="$store.state.config.sync_data" size="mini" icon="el-icon-refresh" @click="tongbuJava"
                    type="primary" plain style="margin-left: 10px;">迁移老版本</el-button> -->
                <!-- <el-button size="mini" icon="el-icon-download" type="primary" plain style="margin-left: 10px;"
                    @click="exportXLS">导出XLS</el-button> -->
            </div>
        </el-row>
        <div class="dividerBar"></div>
        <el-table v-tableFit :data="DataList" height="100%" size="small" stripe border
            style="width:fit-content;width:100%;">
            <el-table-column type="index" width="55" label="序号"></el-table-column>
            <el-table-column prop="dept_name" label="班级名称" width="150"></el-table-column>
            <el-table-column prop="loginname" label="教师工号" width="150"></el-table-column>
            <el-table-column prop="username" label="教师姓名" width="150"></el-table-column>
            <el-table-column prop="title" label="标题" min-width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="content" label="总结内容" width="200" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column prop="plan_time" label="总结时间" width="200"></el-table-column> -->
            <el-table-column prop="create_date" label="提交时间" width="180"></el-table-column>
            <el-table-column fixed="right" label="操作" width="150">
                <template slot-scope="scope">
                    <el-tag class="pointer" type="success" size="mini"
                        v-if="$store.state.teacher.id == scope.row.teacher_id" @click="viewInfo(scope.row)">编辑</el-tag>
                    <el-tag class="pointer" type="primary" size="mini" v-else @click="viewInfo(scope.row)">详情</el-tag>
                    <el-tag v-if="!$store.getters.isGradeLeader(scope.row.class_id)" class="pointer ml10" type="danger"
                        size="mini" @click="deleteJh(scope.row)">删除</el-tag>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
            :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
            :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
        </el-pagination>
        <formdialog v-if="dialogshow" :id="EditItem.id"></formdialog>
    </div>
</template>

<script>
import formdialog from "./formdialog.vue";
import { export_json_to_excel } from "../../../public/js/Export2Excel";
export default {
    components: {
        formdialog
    },
    data() {
        return {
            thisTeacherid: 0,
            dialogshow: false,
            searchForm: {
                class_name: "",
                teacher_name: ""
            },
            EditItem: {
                class_id: 0,
                id: 0,
                title: "",
                class_name: "",
                teacher_name: "",
                teacher_id: 0,
                teacher_code: "",
                plan_desc: "",
                title1: "",
                plan_time: "",
                sort: 99
            },
            teacherInfo: {},
            DataList: [],
            listForm: [],
            listState: [],
            ClassList: [],
            page: {
                count: 0,
                current_page: 1,
                per_page: 50,
                total_page: 0
            },
            isAdmin: false,

        };
    },
    mounted() {
        this.getList();
        this.initTeacherInfo();
        this.$http.post("/api/get_teacher_info").then(res => {
            if (res.data.sys_roles) {
                for (let item of res.data.sys_roles) {
                    if (item.role_name == '系统管理员' || item.role_name == '校领导' || item.role_name == '年级组长' || item.role_code.indexOf("admin") >= 0) {
                        this.isAdmin = true
                        break
                    }
                }
            }
        })
    },
    methods: {
        initTeacherInfo() { },
        getList() {
            this.$http
                .post("/api/employment_summary_list", {
                    type:2,
                    page: this.page.current_page,
                    pagesize: this.page.per_page,
                    class_name: this.searchForm.class_name,
                    teacher_name: this.searchForm.teacher_name,
                })
                .then(res => {
                    this.DataList = res.data.data;
                    this.page = res.data.page;
                });
        },
        deleteJh(row) {
            this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.$http
                        .post("/api/employment_summary_delete", { id: row.id })
                        .then(res => {
                            this.$message({
                                type: "success",
                                message: "删除成功!"
                            });
                        });
                    this.getList();
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });
        },
        handlePageChange(page) {
            this.page.current_page = page;
            this.getList();
        },
        handleSizeChange(val) {
            this.page.current_page = 1;
            this.page.per_page = val;
            this.getList()
        },
        addNew() {
            this.EditItem.id = 0;
            this.dialogshow = true;
        },
        saveJh() {
            this.EditItem = {
                class_id: 0,
                id: 0,
                title: "",
                class_name: "",
                teacher_name: "",
                teacher_id: 0,
                teacher_code: "",
                plan_desc: "",
                title1: "新增院系实习总结",
                plan_time: "",
                sort: 99,
                type: 2
            };
            this.dialogshow = true;
        },
        viewInfo(row) {
            this.EditItem = { ...row };
            this.EditItem.title1 = "院系实习总结详情";
            this.dialogshow = true;
        },
        tongbuJava() {
            this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let loadingInstance = this.$loading({
                    lock: true,
                    text: "已发送请求，请稍等...",
                    spinner: 'el-icon-loading'
                })
                this.$http.post("/api/sync_teacher_summary", {}).then(res => {
                    loadingInstance.close()
                    this.$message.success('后台同步中，请刷新查看')
                    this.getList();
                }).catch(err => {
                    this.$message.error('同步失败')
                    loadingInstance.close()
                })

            }).catch(() => {

            });
        },
        exportXLS() {
            this.$http.post('/api/teacher_plan_list', {
                pagesize: 10000,
                class_name: this.searchForm.class_name,
                teacher_name: this.searchForm.teacher_name,
                type: 2
            }).then(res => {
                let data = res.data.data
                if (!data.length) {
                    this.$message.warning('没有数据导出')
                    return
                }
                let excelData = []
                data.map((item, index) => {
                    excelData.push([
                        ++index,
                        item.teacher_code,
                        item.teacher_name,
                        item.class_name,
                        item.title,
                        item.plan_desc.replace(/<[^>]+>/g, ''),
                        item.plan_time,
                    ])
                })
                export_json_to_excel(['序号', '教师工号', '教师姓名', '班级名称', '标题', '内容', '总结时间'], excelData, '院系实习总结导出')
            })
        },
        exportPDF() {
            let params = {
                "stuArr": [{ name: '院系实习总结' }],
                "pmid": window.pmid,
                "latest": 1
            }
            this.$http.post(this.$pdfUrl + "/export_sxzj_teacher_all", params).then(res => {
                this.$message({
                    type: 'success',
                    message: '生成成功!'
                });
                window.open(res.data.url, "_blank")
            })
        }
    }
};
</script>

<style scoped="scoped">
.spuser {
    display: inline-block;
    border: 1px solid rgb(255, 148, 62);

    padding: 2px;
    margin-right: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    padding: 4px 10px;
}

.spuser img {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
}
</style>
